import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

export const getPartnerDeals = async () => {
  const response = await fetchWrapper(hcUrl('/partner_deals'), buildOpts());
  return handleResponse(response);
};

export const sendPartnerEmailReminders = async ({ params: { slug } }) => {
  const response = await fetchWrapper(
    hcUrl('/partner_deals/email_reminders'),
    buildOpts({
      method: 'POST',
      body: formatBody({ slug }),
    })
  );
  return handleResponse(response);
};
