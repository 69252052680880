import { GET_PARTNER_DEALS_RESPONSE, CREATE_EMAIL_REMINDER_RESPONSE } from 'actions/partnerDeals';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_DEALS_RESPONSE:
      return action.response.partnerDeals;
    case CREATE_EMAIL_REMINDER_RESPONSE:
      return action.response.partnerDeals;
    default:
      return state;
  }
};
