import { makeApiRequest } from 'actions/api';
import { getBasket } from 'actions/baskets';
import { getBehavioralDiscounts } from 'actions/behavioralDiscounts';
import { getMealAddons } from 'actions/mealAddons';
import { getOptIns } from 'actions/optIns';
import { getPartnerDeals } from 'actions/partnerDeals';
import { getStreakOffers } from 'actions/streakOffers';
import { getTasteProfile } from 'actions/tasteProfile';
import { getDetailedUser } from 'actions/user';
import RequestService from 'services/RequestService';

export const bootstrapAccountApp = () => async dispatch => {
  const response = await dispatch(getDetailedUser());
  const menuSlug = document
    .getElementById('hc_account_react_app')
    .getAttribute('data-current-menu-slug');

  if (response.ok && response.user.completedSignup) {
    if (menuSlug) {
      await dispatch(getBasket(menuSlug));
      await dispatch(getBehavioralDiscounts(menuSlug));
    }

    dispatch(getMealAddons());
    dispatch(getOptIns());
    dispatch(getPartnerDeals());
    dispatch(getStreakOffers());
    dispatch(getTasteProfile());
    dispatch(getPauseReasons());
  }
};

export const GET_PAUSE_REASONS = 'GET_PAUSE_REASONS';
export const GET_PAUSE_REASONS_REQUEST = 'GET_PAUSE_REASONS_REQUEST';
export const GET_PAUSE_REASONS_RESPONSE = 'GET_PAUSE_REASONS_RESPONSE';
export const GET_PAUSE_REASONS_ERROR = 'GET_PAUSE_REASONS_ERROR';

export const getPauseReasons = () =>
  makeApiRequest({
    requestType: GET_PAUSE_REASONS,
    method: RequestService.getPauseReasons,
  });
