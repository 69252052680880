import get from 'lodash/get';

import { getBasketSummaries, getBasket, getTracking } from 'actions/baskets';
import { getMenuCategories } from 'actions/menuCategories';
import { getQuickFilters } from 'actions/quickFilters';
import { getMenu } from 'actions/menus';
import { getSuggestedPairings } from 'actions/suggestedPairings';
import { getBehavioralDiscounts } from 'actions/behavioralDiscounts';
import { getStreakOffers } from 'actions/streakOffers';
import { getPendingReviews } from 'actions/reviews';
import { getUser } from 'actions/user';
import { checkOnboardingAndDismissIfStale } from 'actions/dismissals';
import * as fromDashboard from 'reducers/dashboard';

import * as Types from 'utilities/types';

export const BOOTSTRAP_DASHBOARD = 'BOOTSTRAP_DASHBOARD';
export const BOOTSTRAP_DASHBOARD_SUCCESS = 'BOOTSTRAP_DASHBOARD_SUCCESS';
export const BOOTSTRAP_DASHBOARD_ERROR = 'BOOTSTRAP_DASHBOARD_ERROR';

const bootstrapDashboard =
  (refresh = false) =>
  async (dispatch, getState) => {
    const state = getState();

    if (!refresh && !fromDashboard.needsInitializing(state)) {
      return;
    }

    dispatch({ type: BOOTSTRAP_DASHBOARD });
    try {
      await dispatch(getBasketSummaries());
      const userResponse = await dispatch(getUser());

      if (userResponse.ok) {
        dispatch(checkOnboardingAndDismissIfStale(userResponse.user));
      }

      dispatch({ type: BOOTSTRAP_DASHBOARD_SUCCESS });
    } catch (e) {
      dispatch({ type: BOOTSTRAP_DASHBOARD_ERROR });
    }
  };

export const bootstrapUpcomingOrders = () => async dispatch => {
  await dispatch(bootstrapDashboard());
  const basketResponses = await dispatch(bootstrapInitialBaskets());
  dispatch(fetchReviewsForDeliveredBasket(basketResponses));
  dispatch(fetchTracking(basketResponses));
};

export const bootstrapEditBasket = menuSlug => async dispatch => {
  await dispatch(fetchCompleteBasket(menuSlug));
  await dispatch(bootstrapDashboard());
  await dispatch(getMenuCategories(menuSlug));
  await dispatch(getQuickFilters(menuSlug));
  await dispatch(getBehavioralDiscounts(menuSlug));
};

export const bootstrapOrderSummary = menuSlug => async dispatch => {
  await dispatch(fetchCompleteBasket(menuSlug));
  await dispatch(bootstrapDashboard());
  await dispatch(bootstrapInitialBaskets());
};

export const bootstrapCalendar = () => async dispatch => {
  await dispatch(bootstrapAllBaskets());
};

const bootstrapAllBaskets = () => async (dispatch, getState) => {
  const state = getState();
  const menuSlugs = fromDashboard.selectMenuSlugs(state);
  return await dispatch(batchFetchCompleteBaskets(menuSlugs));
};

const bootstrapInitialBaskets = () => async (dispatch, getState) => {
  const state = getState();
  const menuSlugs = fromDashboard.selectInitialMenuSlugs(state);
  return await dispatch(batchFetchCompleteBaskets(menuSlugs));
};

const batchFetchCompleteBaskets = (menuSlugs, refresh = false) => {
  return dispatch => {
    return Promise.all(menuSlugs.map(menuSlug => dispatch(fetchCompleteBasket(menuSlug, refresh))));
  };
};

export const fetchCompleteBasket = (menuSlug, refresh = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const shouldFetchMenu = refresh || fromDashboard.menuNeedsInitialization(state, menuSlug);
    const shouldFetchBasket = refresh || fromDashboard.basketNeedsInitialization(state, menuSlug);
    const promises = [
      shouldFetchBasket && dispatch(getBasket(menuSlug)),
      shouldFetchMenu && dispatch(getMenu(menuSlug)),
      dispatch(getSuggestedPairings(menuSlug)),
      dispatch(getBehavioralDiscounts(menuSlug)),
      dispatch(getStreakOffers()),
    ].filter(Boolean);
    return Promise.all(promises);
  };
};

const fetchReviewsForDeliveredBasket = basketResponses => dispatch => {
  const baskets = basketResponses.map(basketResponse => get(basketResponse[0], 'weeklyBasket'));
  const deliveredBasket = baskets.find(
    basket => get(basket, 'displayStatus') === Types.DELIVERED_BASKET_STATUS
  );
  if (deliveredBasket) dispatch(getPendingReviews({ limit: deliveredBasket.meals.length }));
};

const fetchTracking = basketResponses => dispatch => {
  const baskets = basketResponses.map(basketResponse => get(basketResponse[0], 'weeklyBasket'));
  const basketWithTrackingLink = baskets.find(basket =>
    [
      Types.DELIVERED_BASKET_STATUS,
      Types.PROCESSED_BASKET_STATUS,
      Types.SHIPPED_BASKET_STATUS,
    ].includes(get(basket, 'displayStatus'))
  );
  if (basketWithTrackingLink) dispatch(getTracking(basketWithTrackingLink.menu.slug));
};

export const refreshMenuFromDeliveryDayChange = menuSlug => async dispatch => {
  await dispatch(getMenu(menuSlug));
};
