import { parseStyleVar } from 'helpers/styleVariables';
import useViewportWidth from 'hooks/useViewportWidth';
import styleVars from 'styles/shared/jsVariables';

const MOBILE_MAX_WIDTH = parseStyleVar(styleVars.bp1); // 550px
const LARGE_TABLET_MIN_WIDTH = 768;
const TABLET_MAX_WIDTH = parseStyleVar(styleVars.bp2); // 850px
const DESKTOP_BP_3 = parseStyleVar(styleVars.bp3); // 1100px

const useScreenSizeConstants = () => {
  const { width } = useViewportWidth();
  const isMobile = width <= MOBILE_MAX_WIDTH;
  const isTablet = width > MOBILE_MAX_WIDTH && width <= TABLET_MAX_WIDTH;
  const isMobileOrTablet = isMobile || isTablet;
  const isLargeTablet = width >= LARGE_TABLET_MIN_WIDTH && width <= TABLET_MAX_WIDTH;
  const isDesktop = width > TABLET_MAX_WIDTH;
  const isLargeDesktop = width > DESKTOP_BP_3;
  const isAtDesktopBp3 = width === DESKTOP_BP_3;

  return {
    isMobile,
    isTablet,
    isMobileOrTablet,
    isLargeTablet,
    isDesktop,
    isLargeDesktop,
    isAtDesktopBp3,
  };
};

export default useScreenSizeConstants;
