import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_PARTNER_DEALS = 'GET_PARTNER_DEALS';
export const GET_PARTNER_DEALS_REQUEST = 'GET_PARTNER_DEALS_REQUEST';
export const GET_PARTNER_DEALS_RESPONSE = 'GET_PARTNER_DEALS_RESPONSE';
export const GET_PARTNER_DEALS_ERROR = 'GET_PARTNER_DEALS_ERROR';

export const CREATE_EMAIL_REMINDER = 'CREATE_EMAIL_REMINDER';
export const CREATE_EMAIL_REMINDER_REQUEST = 'CREATE_EMAIL_REMINDER_REQUEST';
export const CREATE_EMAIL_REMINDER_RESPONSE = 'CREATE_EMAIL_REMINDER_RESPONSE';
export const CREATE_EMAIL_REMINDER_ERROR = 'CREATE_EMAIL_REMINDER_ERROR';

export const getPartnerDeals = () =>
  makeApiRequest({
    requestType: GET_PARTNER_DEALS,
    method: RequestService.getPartnerDeals,
  });

export const sendPartnerEmailReminders = slug =>
  makeApiRequest({
    type: CREATE_EMAIL_REMINDER,
    method: RequestService.sendPartnerEmailReminders,
    params: { slug },
  });
