import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_STREAK_OFFERS = 'GET_STREAK_OFFERS';
export const GET_STREAK_OFFERS_REQUEST = 'GET_STREAK_OFFERS_REQUEST';
export const GET_STREAK_OFFERS_RESPONSE = 'GET_STREAK_OFFERS_RESPONSE';
export const GET_STREAK_OFFERS_ERROR = 'GET_STREAK_OFFERS_ERROR';

export const getStreakOffers = () =>
  makeApiRequest({
    requestType: GET_STREAK_OFFERS,
    method: RequestService.getStreakOffers,
  });
