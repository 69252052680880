import find from 'lodash/find';
import get from 'lodash/get';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import basketEdits, * as fromBasketEdits from './basketEdits';
import baskets, * as fromBaskets from './baskets';
import basketStatus, * as fromBasketStatus from './basketStatus';
import behavioralDiscounts, * as fromBehavioralDiscounts from './behavioralDiscounts';
import customizeIt, * as fromCustomizeIt from './customizeIt';
import mealOptionGroups, * as fromMealOptionGroups from './mealOptionGroups';
import menuCategories, * as fromMenuCategories from './menuCategories';
import menuMeals, * as fromMenuMeals from './menuMeals';
import menus, * as fromMenu from './menus';
import menuSlugs from './menuSlugs';
import menuStatus, * as fromMenuStatus from './menuStatus';
import modalMeals, * as fromModalMeals from './modalMeals';
import modalMealsCategory, * as fromModalMealsCategory from './modalMealsCategory';
import quickFilters from './quickFilters';
import reviews, * as fromReviews from './reviews';
import scroll, * as fromScroll from './scroll';
import status, * as fromStatus from './status';
import suggestedPairings, * as fromSuggestedPairings from './suggestedPairings';
import streakOffers from './streakOffers';

export default combineReducers({
  baskets,
  basketEdits,
  basketStatus,
  behavioralDiscounts,
  customizeIt,
  mealOptionGroups,
  menuCategories,
  menuMeals,
  menus,
  menuSlugs,
  menuStatus,
  modalMeals,
  modalMealsCategory,
  quickFilters,
  reviews,
  scroll,
  status,
  suggestedPairings,
  streakOffers,
});

const selectDashboard = state => state.dashboard;

export const selectQuickFilters = state => selectDashboard(state).quickFilters;
export const selectBaskets = state => selectDashboard(state).baskets;
export const selectBasketEdits = state => selectDashboard(state).basketEdits;
export const selectCustomizeIt = state => selectDashboard(state).customizeIt;
export const selectMealOptions = state => selectDashboard(state).mealOptions;
export const selectMenus = state => selectDashboard(state).menus;
export const selectMealOptionGroups = state => selectDashboard(state).mealOptionGroups;
export const selectMenuSlugs = state => selectDashboard(state).menuSlugs;
export const selectMenuCategories = state => selectDashboard(state).menuCategories;
export const selectMenuMeals = state => selectDashboard(state).menuMeals;
export const selectModalMeals = state => selectDashboard(state).modalMeals;
export const selectModalMealsCategory = state => selectDashboard(state).modalMealsCategory;
export const selectScroll = state => selectDashboard(state).scroll;
export const selectStatus = state => selectDashboard(state).status;
export const selectMeal = (state, menuSlug, mealId) => {
  return (
    find(selectMealsForBasket(state, menuSlug), { id: mealId }) ||
    find(selectMealsForMenu(state, menuSlug), { id: mealId })
  );
};
export const selectAvailableBehavioralDiscounts = state =>
  selectDashboard(state).behavioralDiscounts;
export const selectAvailableSuggestedPairings = state => selectDashboard(state).suggestedPairings;
export const selectAvailableStreakOffers = state => selectDashboard(state).streakOffers;
export const selectReviews = state => selectDashboard(state).reviews;
export const selectBasket = (state, menuSlug) =>
  fromBaskets.selectBasket(selectBaskets(state), menuSlug);
export const selectMealOptionSelectionsForBasket = (state, menuSlug) =>
  fromBaskets.selectMealOptionSelectionsForBasket(selectBaskets(state), menuSlug);
export const selectMealOptionSelectionsForBasketAndMeal = (state, menuSlug, mealId) =>
  fromBaskets.selectMealOptionSelectionsForBasketAndMeal(selectBaskets(state), menuSlug, mealId);

export const selectMenu = (state, menuSlug) => fromMenu.selectMenu(selectMenus(state), menuSlug);
export const selectMealAddons = createSelector(selectMenuMeals, menuMeals =>
  fromMenuMeals.selectMealAddons(menuMeals)
);
export const selectOptIns = createSelector(selectMenuMeals, menuMeals =>
  fromMenuMeals.selectOptIns(menuMeals)
);
export const selectMealsForMenu = (state, menuSlug) =>
  fromMenuMeals.selectMealsForMenu(selectMenuMeals(state), menuSlug);
export const selectNonDonationMealsForMenu = (state, menuSlug) =>
  fromMenuMeals.selectNonDonationMealsForMenu(selectMenuMeals(state), menuSlug);
export const selectMealsForBasket = (state, menuSlug) =>
  fromBaskets.selectMealsForBasket(selectBaskets(state), menuSlug);
export const selectModalMealsForMenu = (state, menuSlug) =>
  fromModalMeals.selectModalMealsForMenu(selectModalMeals(state), menuSlug);

export const selectModalMealsCategoryForMenu = (state, menuSlug) =>
  fromModalMealsCategory.selectModalMealsCategory(selectModalMealsCategory(state), menuSlug);

const selectAvailableMealOptionGroups = createSelector(
  selectMenuSlugs,
  selectMealOptionGroups,
  selectBaskets,
  (menuSlugs, mealOptionGroups, baskets) => {
    const isOptionSelected = (menuSlug, mealId, optionId) => {
      const optionSelectionsForMeal = fromBaskets.selectMealOptionSelectionsForBasketAndMeal(
        baskets,
        menuSlug,
        mealId
      );

      return optionSelectionsForMeal.some(selection => selection.mealOptionId === optionId);
    };

    const isOptionAvailable = (menuSlug, mealId, option) => {
      if (!option.soldOut) return true;
      return isOptionSelected(menuSlug, mealId, option.id);
    };

    return menuSlugs.reduce((availableGroupsByMenu, menuSlug) => {
      const allGroupsForMenu = fromMealOptionGroups.selectMealOptionGroupsForMenu(
        mealOptionGroups,
        menuSlug
      );

      const availableGroupsForMenu = allGroupsForMenu
        .map(group => {
          const availableOptions = group.mealOptions.filter(option =>
            isOptionAvailable(menuSlug, group.mealId, option)
          );

          return {
            ...group,
            mealOptions: availableOptions,
          };
        })
        .filter(group => group.mealOptions.length > 1);

      return {
        ...availableGroupsByMenu,
        [menuSlug]: availableGroupsForMenu,
      };
    }, {});
  }
);

export const selectBehavioralDiscountsForMenu = (state, menuSlug) =>
  fromBehavioralDiscounts.selectBehavioralDiscountsForMenu(
    selectAvailableBehavioralDiscounts(state),
    menuSlug
  );

export const selectSuggestedPairingsForMenu = (state, menuSlug) =>
  fromSuggestedPairings.selectSuggestedPairingsForMenu(
    selectAvailableSuggestedPairings(state),
    menuSlug
  );

export const selectSelectedSuggestedPairingsForMenu = (state, menuSlug) =>
  fromSuggestedPairings.selectSelectedSuggestedPairingsForMenu(
    selectAvailableSuggestedPairings(state),
    menuSlug
  );

export const selectSelectedSuggestedPairingForMenuAndHostMeal = (state, menuSlug, hostMealId) =>
  fromSuggestedPairings.selectSelectedSuggestedPairingForMenuAndHostMeal(
    selectAvailableSuggestedPairings(state),
    menuSlug,
    hostMealId
  );

export const selectMealOptionGroupsForMenu = (state, menuSlug) =>
  fromMealOptionGroups.selectMealOptionGroupsForMenu(
    selectAvailableMealOptionGroups(state),
    menuSlug
  );

export const selectMealOptionGroupsForMenuAndMeal = (state, menuSlug, mealId) =>
  fromMealOptionGroups.selectMealOptionGroupsForMenuAndMeal(
    selectAvailableMealOptionGroups(state),
    menuSlug,
    mealId
  );

export const selectMealOptionGroup = (state, menuSlug, mealId, mealOptionGroupId) =>
  fromMealOptionGroups.selectMealOptionGroup(
    selectMealOptionGroups(state),
    menuSlug,
    mealId,
    mealOptionGroupId
  );

const selectBasketStatus = state => selectDashboard(state).basketStatus;
const selectMenuStatus = state => selectDashboard(state).menuStatus;

export const basketNeedsInitialization = (state, menuSlug) =>
  fromBasketStatus.needsInitialization(selectBasketStatus(state), menuSlug);
export const isBasketLoading = (state, menuSlug) =>
  fromBasketStatus.isBasketLoading(selectBasketStatus(state), menuSlug);
export const isBasketError = (state, menuSlug) =>
  fromBasketStatus.isBasketError(selectBasketStatus(state), menuSlug);
export const removedMeals = (state, menuSlug) =>
  fromBasketStatus.removedMeals(selectBasketStatus(state), menuSlug);

export const isInitializationComplete = state =>
  fromStatus.isInitializationComplete(selectStatus(state));
export const needsInitializing = state => fromStatus.needsInitializing(selectStatus(state));
export const isUnauthorized = state => fromStatus.isUnauthorized(selectStatus(state));

export const menuNeedsInitialization = (state, menuSlug) =>
  fromMenuStatus.needsInitialization(selectMenuStatus(state), menuSlug);
export const isMenuLoading = (state, menuSlug) =>
  fromMenuStatus.isMenuLoading(selectMenuStatus(state), menuSlug);
export const isMenuError = (state, menuSlug) =>
  fromMenuStatus.isMenuError(selectMenuStatus(state), menuSlug);

export const selectScrollPositionForRoute = (state, route) =>
  fromScroll.selectScrollPositionForRoute(selectScroll(state), route);

export const selectEditableMenuSlugs = createSelector(
  selectMenuSlugs,
  selectBaskets,
  (menuSlugs, baskets) => menuSlugs.filter(slug => get(baskets, [slug, 'futureBasket'], false))
);

export const selectInitialMenuSlugs = createSelector(selectMenuSlugs, menuSlugs =>
  menuSlugs.slice(0, 2)
);

const sortAsc = (x, y) => x - y;

export const selectOrderedBaskets = createSelector(selectBaskets, baskets => {
  return Object.values(baskets).sort((basketA, basketB) => {
    return sortAsc(new Date(basketA.menu.endsAt), new Date(basketB.menu.endsAt));
  });
});

export const selectNextOrderableBasket = createSelector(selectOrderedBaskets, orderedBaskets => {
  return find(orderedBaskets, {
    showDiscounts: true,
    scheduled: true,
    futureBasket: true,
  });
});

export const selectNextOrderableSkippedBasket = createSelector(
  selectOrderedBaskets,
  orderedBaskets => {
    return find(orderedBaskets, {
      futureBasket: true,
      skipped: true,
    });
  }
);

export const selectCustomizeItForMealId = (state, mealId) =>
  fromCustomizeIt.selectCustomizeItForMealId(selectCustomizeIt(state), mealId);

export const selectMealServingsForBasketEdits = state =>
  fromBasketEdits.selectServings(selectBasketEdits(state));
export const selectMealOptionsForBasketEdits = state =>
  fromBasketEdits.selectMealOptions(selectBasketEdits(state));
export const selectMealOptionsForBasketAndMeal = (state, mealId) =>
  fromBasketEdits.selectMealOptions(state, mealId);
export const selectSubtotalsForBasketEdits = state =>
  fromBasketEdits.selectSubtotals(selectBasketEdits(state));
export const selectPairingsForBasketEdits = state =>
  fromBasketEdits.selectSuggestedPairings(selectBasketEdits(state));

export const selectReview = (state, mealId) =>
  fromReviews.selectReview(selectReviews(state), mealId);
export const selectComments = state => fromReviews.selectComments(selectReviews(state));

export const selectMenuCategoriesLoading = (state, slug) =>
  fromMenuCategories.selectMenuCategoriesLoading(selectMenuCategories(state), slug);
export const selectMenuCategoriesData = (state, slug) =>
  fromMenuCategories.selectMenuCategoriesData(selectMenuCategories(state), slug);
